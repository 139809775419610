import { Theme } from '@mui/material/styles'
import { useMemo } from 'react'

import makeAveraTheme from 'themes/makeAveraTheme'
import makeTheme from 'themes/makeTheme'
import { AppConfig, IssuerConfig } from 'utils/getAppConfig'

const useMakeTheme = (appConfig: AppConfig): Theme => {
  const issuerConfig: Partial<IssuerConfig> = appConfig.issuer?.config || {}
  const { themeName } = issuerConfig

  return useMemo(() => {
    switch (themeName) {
      case 'avera':
        return makeAveraTheme()
      default:
        return makeTheme()
    }
  }, [themeName])
}

export default useMakeTheme
