const PATHS = {
  login: '/login',
  resetPassword: '/reset_password',
  forgotPassword: '/forgot_password',
  loginPost: '/api/sessions',
  unauthorized: '/unauthorized',
  manage: {
    autopayConfirmation: (accountNumber: string) =>
      `/manage/members/${accountNumber}/autopay/confirm`,
    autopayDisclaimer: (accountNumber: string) =>
      `/manage/members/${accountNumber}/autopay/disclaimer`,
    autopayForm: (accountNumber: string) => `/manage/members/${accountNumber}/autopay/form`,
    billing: (accountNumber: string) => `/manage/members/${accountNumber}/billing`,
    correspondence: (accountNumber: string) => `/manage/members/${accountNumber}/correspondence`,
    coverage: (accountNumber: string) => `/manage/members/${accountNumber}/coverage`,
    demographics: (accountNumber: string) => `/manage/members/${accountNumber}/demographics`,
    inboundFiles: '/manage/inbound_files',
    members: '/manage/members',
    reports: '/manage/reports',
    stripeExpressDashboard: '/manage/stripe_express_dashboard',
    users: {
      index: '/manage/users',
      form: '/manage/users/form',
    },
  },
  members: {
    autopayConfirmation: '/members/autopay/confirm',
    autopayDisclaimer: '/members/autopay/disclaimer',
    autopayForm: '/members/autopay/form',
    billing: '/members/billing',
    correspondence: '/members/correspondence',
    coverage: '/members/coverage',
    dashboard: '/members',
    demographics: '/members/demographics',
  },
  applicants: {
    binders: {
      lookupForm: '/applicants/binders',
      payAch: '/applicants/binders/pay_ach',
      payCc: '/applicants/binders/pay_cc',
    },
    paymentRedirect: {
      choosePaymentMethod: '/applicants/payment_redirect',
      payAch: '/applicants/payment_redirect/pay_ach',
      payCc: '/applicants/payment_redirect/pay_cc',
    },
    paymentConfirmation: '/applicants/payment_confirmation',
  },
  sso: {
    error: '/sso_error',
  },
}

export default PATHS
