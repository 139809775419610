// Returns new object without the provided key
const omit = <T, K extends keyof T, U = Omit<T, K>>(obj: T, key: K): U => {
  const newObj = {} as U
  Object.keys(obj).forEach((k) => {
    if (key !== k) {
      newObj[k] = obj[k]
    }
  })

  return newObj
}

export default omit
