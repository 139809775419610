import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'

const IssuerIdentity = ({ name, logoUrl }: { name?: string; logoUrl?: string }) => {
  if (!name && !logoUrl) {
    return null
  }

  if (!logoUrl) {
    return <Typography variant="h6">{name}</Typography>
  }

  return <Box component="img" height="2.5rem" width="auto" alt={`${name} logo`} src={logoUrl} />
}

export default IssuerIdentity
