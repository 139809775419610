import ApiService from 'apis/apiService'
import { User } from 'apis/sessions/types'

interface LoginResponse {
  user: User
}

const SESSIONS_PATH = '/api/sessions'

export interface LoginParams {
  email: string
  password: string
  rememberMe?: boolean
}

const login = ({ email, password, rememberMe = false }: LoginParams) =>
  ApiService.post<LoginResponse>(SESSIONS_PATH, { user: { email, password, rememberMe } })

const isLoggedIn = () => ApiService.get<LoginResponse | null>(SESSIONS_PATH)

const logout = () => ApiService.delete<void>(SESSIONS_PATH)

export default { login, logout, isLoggedIn }
