import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import React, { ReactNode } from 'react'

import ProfileMenu from 'components/shared/Header/ProfileMenu'
import IssuerIdentity from 'components/shared/IssuerIdentity'
import useAppConfig from 'hooks/useAppConfig'

const Header = ({ menuItem, navLinks }: { menuItem?: ReactNode; navLinks?: ReactNode }) => {
  const appConfig = useAppConfig()
  const { issuer, user } = appConfig
  const logoUrl = issuer?.config?.logoUrl
  const name = issuer?.name || 'EBR'

  return (
    <AppBar>
      <Toolbar>
        {menuItem}
        <Box sx={{ pr: 6 }}>
          <IssuerIdentity name={name} logoUrl={logoUrl} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>{navLinks}</Box>
        {user && <ProfileMenu />}
      </Toolbar>
    </AppBar>
  )
}

export default Header
