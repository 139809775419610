import { blueGrey } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'

import makeTheme from 'themes/makeTheme'

const addAveraFont = () => {
  if (document.querySelector('link[rel=stylesheet][href*=Montserrat]')) return

  const fontDiv = document.createElement('link')
  fontDiv.rel = 'stylesheet'
  fontDiv.href =
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'
  document.head.appendChild(fontDiv)
}

const makeAveraTheme = (): Theme => {
  addAveraFont()

  return makeTheme({
    customTheme: {
      palette: {
        primary: {
          light: '#08956c',
          main: '#007852',
          dark: '#05262C',
          contrastText: '#fff',
        },
        secondary: {
          light: '#bfdda1',
          main: '#80BC42',
          dark: '#5d9931',
          contrastText: '#000',
        },
        neutral: {
          light: blueGrey[50],
          main: blueGrey[500],
          dark: blueGrey[800],
          contrastText: '#fff',
        },
        background: {
          default: '#F6F8FB',
        },
      },
    },
    fontFamily: '"Montserrat"',
  })
}

export default makeAveraTheme
