import { useState } from 'react'

import omit from 'utils/omit'

interface PromiseStatus {
  isReady: boolean
  isPending: boolean
  isResolved: boolean
  isError: boolean
}

export interface PromiseState<T> {
  status: Readonly<PromiseStatus>
  response: T | null
  error: string | null
}

export type InitialPromiseState<T> = Partial<Omit<PromiseState<T>, 'status'>> & {
  status?: Readonly<RequireOnlyOne<ConvertAllValues<PromiseStatus, true>>>
}

const BASE_STATUS = {
  isReady: false,
  isPending: false,
  isResolved: false,
  isError: false,
}

const DEFAULT_STATE: PromiseState<null> = {
  status: {
    ...BASE_STATUS,
    isReady: true,
  },
  response: null,
  error: null,
}

const usePromise = <
  T extends (...args: never[]) => Promise<unknown>,
  U extends Awaited<ReturnType<T>>,
>(
  func: T,
  initialState: InitialPromiseState<U> = {},
): [Readonly<PromiseState<U>>, (...args: Parameters<T>) => void] => {
  const [state, setState] = useState<PromiseState<U>>({
    ...DEFAULT_STATE,
    ...omit(initialState, 'status'),
    status: {
      ...BASE_STATUS,
      ...(initialState.status || DEFAULT_STATE.status),
    },
  })

  const onSuccess = (response: Awaited<U>) => {
    setState({
      status: {
        ...BASE_STATUS,
        isResolved: true,
      },
      error: null,
      response,
    })
  }

  const onError = (error: { message: string }) => {
    setState({
      status: {
        ...BASE_STATUS,
        isError: true,
      },
      error: error.message || 'An error occurred',
      response: null,
    })
  }

  const wrappedFunction = (...args: Parameters<T>) => {
    setState({
      ...state,
      status: {
        ...BASE_STATUS,
        isPending: true,
      },
    })

    func(...args).then(onSuccess, onError)
  }

  return [state, wrappedFunction]
}

export default usePromise
