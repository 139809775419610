import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import React, { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import Header from '../Header'

const Layout = ({
  header = <Header />,
  footer = null,
}: {
  header?: ReactNode
  footer?: ReactNode | false
}) => (
  <Box
    sx={{
      display: 'flex',
      minHeight: '100vh',
      bgcolor: 'background.default',
      flexDirection: 'column',
    }}
  >
    <CssBaseline />
    {header}
    <Box component="main" sx={{ flexGrow: 1, mt: 10 }}>
      <Box
        sx={{
          p: 3,
          maxWidth: '1150px',
          margin: '0 auto',
        }}
      >
        <Outlet />
      </Box>
    </Box>
    {footer}
  </Box>
)

export default Layout
