import React from 'react'

import PromiseGate, { PromiseGateProps } from 'components/shared/PromiseGate'
import useAppConfig from 'hooks/useAppConfig'

export interface RoleGateProps extends Omit<PromiseGateProps, 'promise' | 'deps'> {
  roles: string[]
}

const RoleGate = ({ roles, ...props }: RoleGateProps) => {
  const { user } = useAppConfig()

  return (
    <PromiseGate
      {...props}
      promise={() => Promise.resolve(roles.some((role) => user?.globalRoles?.includes(role)))}
      deps={roles}
    />
  )
}

export default RoleGate
