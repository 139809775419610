import React from 'react'

import useEnabled from 'hooks/useEnabled'

export interface PromiseGateProps {
  children?: React.ReactNode
  fallback?: React.ReactNode
  initialValue?: boolean
  loadingComponent?: React.ReactNode
  deps?: React.DependencyList
  errorComponent?: (error: string | null) => React.ReactNode
  promise: () => Promise<boolean>
}

const PromiseGate = ({
  promise,
  children = null,
  initialValue = false,
  fallback = null,
  loadingComponent = null,
  deps = [],
  errorComponent = null,
}: PromiseGateProps) => {
  const { status, enabled, error } = useEnabled(promise, {
    initialState: {
      enabled: initialValue,
    },
    deps,
  })

  if (status.isPending || status.isReady) return loadingComponent
  if (status.isError && errorComponent) return errorComponent(error)

  return enabled ? children : fallback
}

export default PromiseGate
