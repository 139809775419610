import { DependencyList, ReactNode, useEffect } from 'react'

// replace option removes the current route from the history to prevent back
const Redirect = ({
  to,
  children,
  replace = false,
  delay = 0,
  deps = [],
}: {
  to: string
  children?: ReactNode
  replace?: boolean
  delay?: number
  deps?: DependencyList
}) => {
  useEffect(() => {
    const { location } = window
    const timeout = setTimeout(() => (replace ? location.replace(to) : location.assign(to)), delay)
    return () => clearTimeout(timeout)
  }, deps)

  return children
}

export default Redirect
