import {
  init as sentryInit,
  browserTracingIntegration,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react'
import React from 'react'

sentryInit({
  dsn: process.env.SENTRY_DSN,
  integrations: [browserTracingIntegration()],
  environment: process.env.ENVIRONMENT,
  attachStacktrace: true,
  tracesSampleRate: 1.0,
})

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <SentryErrorBoundary fallback={<p>Oops, an error has occurred</p>}>
    {children}
  </SentryErrorBoundary>
)

export default ErrorBoundary
