import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload'
import LogoutIcon from '@mui/icons-material/Logout'
import Person from '@mui/icons-material/Person'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import loginApi from 'apis/sessions/loginApi'
import Redirect from 'components/shared/Redirect'
import RoleGate from 'components/shared/RoleGate'
import PATHS from 'constants/paths'
import useAppConfig from 'hooks/useAppConfig'
import usePromise from 'hooks/usePromise'

const Header = () => {
  const appConfig = useAppConfig()
  const {
    issuer,
    user: { email, firstName, lastName, organizationalUnitType },
  } = appConfig
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [{ status }, logoutAction] = usePromise(loginApi.logout)

  if (status.isResolved) return <Redirect to={PATHS.login} />

  const formattedRole = [issuer?.name, organizationalUnitType === 'member' ? 'Member' : 'Admin']
    .filter(Boolean)
    .join(' ')
  const name = firstName || email || 'Profile'
  const shortName = firstName && lastName ? `${firstName[0]}${lastName[0]}` : email && email[0]

  return (
    <Box>
      <CardHeader
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
        avatar={<Avatar>{shortName || <Person />}</Avatar>}
        title={<Typography variant="h6">{name}</Typography>}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">{formattedRole}</Typography>
          <Typography variant="body2">{email}</Typography>
        </Box>
        <Divider />
        <RoleGate roles={['financial_admin']}>
          <MenuItem>
            <Button
              to={PATHS.manage.stripeExpressDashboard}
              target="_blank"
              rel="noreferrer"
              component={RouterLink}
              variant="text"
              startIcon={<AssuredWorkloadIcon />}
            >
              Stripe Express Dashboard
            </Button>
          </MenuItem>
          <Divider />
        </RoleGate>
        <MenuItem>
          <Button onClick={logoutAction} variant="text" startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Header
