import { DependencyList, useEffect } from 'react'

import usePromise, { InitialPromiseState, PromiseState } from 'hooks/usePromise'

export interface EnabledState extends Omit<PromiseState<boolean>, 'response'> {
  enabled: boolean
}

export interface EnabledOptions {
  initialState?: Omit<InitialPromiseState<boolean>, 'response'> & { enabled?: boolean }
  deps?: DependencyList
}

const useEnabled = (
  promise: () => Promise<boolean>,
  { initialState = {}, deps = [] }: EnabledOptions = {},
): EnabledState => {
  const [enabledState, enabledAction] = usePromise(promise, {
    ...initialState,
    response: initialState.enabled || false,
  })

  useEffect(() => {
    enabledAction()
  }, deps)

  return { ...enabledState, enabled: enabledState.response }
}

export default useEnabled
